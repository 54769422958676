import React from "react"
import HomeSubtitle from "../home-subtitle/HomeSubtitle"
import HomeNewsArticle from "../home-news-article/HomeNewsArticle";
import "./homeNews.scss"

const HomeNews = (props) => {
  const {category, title, articles } = props;

  const articlesColumn1 = articles.filter(article => article?.data?.article_homepage_order === 1 || article?.data?.article_homepage_order === 2);
  const articleColumn2 = articles.find(article => article?.data?.article_homepage_order === 3);
  const articleColumn3 = articles.find(article => article?.data?.article_homepage_order === 4);

  return (
    <section className="homeNews">
      <div className="homeNews_column">
        <HomeSubtitle className="mobileTitle" category={category}>{title?.richText[0]}</HomeSubtitle>
        {articlesColumn1?.map((article, index) => <HomeNewsArticle key={`HomeNewsArticle${index}`} article={article?.data} />)}
      </div>
      <div className="homeNews_column">
        <HomeNewsArticle article={articleColumn2?.data} />
      </div>
      <div className="homeNews_column">
       <HomeSubtitle className="desktopTitle" category={category}>{title?.richText[0]}</HomeSubtitle>
       <HomeNewsArticle article={articleColumn3?.data} />
      </div>
    </section>
  )
}

export default HomeNews
